import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-payments-filter',
  templateUrl: './payments-filter.component.html',
  styleUrls: ['./payments-filter.component.scss'],
})
export class PaymentsFilterComponent {
  @Input() filterForm: FormGroup;
  @Input() paymentStatuses: { value: string; display: string }[];
}
