import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ToastService } from '@techspert-io/user-alerts';
import {
  IOpportunityOptions,
  OpportunityConferenceProviderOptionLabels,
  OpportunityConferenceProviders,
} from '../../../models/opportunity.models';

@Component({
  selector: 'app-settings-form',
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss'],
})
export class SettingsFormComponent implements OnChanges {
  @Input() opportunity: IOpportunityOptions;
  @Input() defaultConferenceProvider: string;
  @Output() opportunityChange = new EventEmitter<IOpportunityOptions>();

  automatedSchedulingTooltip = '';
  automatedSchedulingDisabled = false;

  constructor(private toastService: ToastService) {}

  ngOnChanges(): void {
    this.checkConferenceProvider();
  }

  onChange(value: boolean, field: string): void {
    switch (field) {
      case 'conferenceReminderDefaultSettings':
        this.opportunity[field] = {
          enabled: value,
          sendEmailBeforeSeconds: [3600, 86400],
        };
        break;
      case 'enrichedSchedulerInvite':
        if (!value || !this.opportunity.excludeExpertPIIForClientEmails) {
          this.opportunity[field] = value;
        }
        break;
      case 'excludeExpertPIIForClientEmails':
        if (!value || !this.opportunity.enrichedSchedulerInvite) {
          this.opportunity[field] = value;
        }
        break;
      case 'automatedScheduling':
        this.opportunity[field] = value;
        this.checkConferenceProvider();
        break;
      default:
        this.opportunity[field] = value;
        break;
    }
    this.opportunityChange.emit(this.opportunity);
  }

  private checkConferenceProvider(): void {
    const providerLabel =
      OpportunityConferenceProviderOptionLabels[this.defaultConferenceProvider];

    if (
      [
        OpportunityConferenceProviders.Other,
        OpportunityConferenceProviders.ClientHosted,
      ].includes(
        this.defaultConferenceProvider as OpportunityConferenceProviders
      )
    ) {
      this.automatedSchedulingDisabled = true;
      this.automatedSchedulingTooltip = `This is not possible due to the default conference provider being ${providerLabel}`;

      if (this.opportunity.automatedScheduling) {
        this.toastService.sendMessage(
          `Automated scheduling has been disabled due to the default conference provider being ${providerLabel}`,
          'error'
        );
        this.onChange(false, 'automatedScheduling');
      }
      return;
    }

    this.automatedSchedulingDisabled = false;
    this.automatedSchedulingTooltip =
      'If active when an expert gets accepted, scheduling & creation of engagements will be automated.';
  }
}
