<dialog style="display: none" class="payments-csv-dialog" #PaymentsCsvDialog>
  <h2>Download CSV</h2>
  <div class="checkbox">
    <mat-checkbox [(ngModel)]="updatePaymentStatus"></mat-checkbox>
    <span class="label">Update 'not paid' experts to 'payment sent'</span>
  </div>
  <div class="payment-csv-dialog-button-bar">
    <button class="button button--secondary" (click)="closePaymentsCsvDialog()">Close</button>
    <button class="button button--primary" (click)="downloadEngagementsCsvHandler()">Download</button>
  </div>
</dialog>
<div class="payments-panel flex flex-col gap-2">
  <div class="flex flex-row flex-wrap gap-2">
    <div class="flex-1">
      <h1 class="txp-h1">Project payments</h1>
      <span class="txp-h3">Filter by Payment Type, Paid Status select and download as a .CSV file</span>
    </div>

    <app-payments-filter [filterForm]="filterForm" [paymentStatuses]="paymentStatuses"></app-payments-filter>
  </div>

  <div class="flex flex-row flex-wrap">
    <form [formGroup]="searchForm" class="flex flex-row flex-nowrap gap-1 flex-1">
      <mat-form-field>
        <mat-label>Search</mat-label>
        <input matInput formControlName="search">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Payment Types</mat-label>
        <mat-select formControlName="paymentTypes" multiple>
          <mat-option *ngFor="let paymentType of paymentTypes" [value]="paymentType">
            {{paymentType}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <form [formGroup]="filterForm" class="flex flex-row flex-nowrap gap-1">
      <mat-form-field>
        <mat-label>Date min (DOE)</mat-label>
        <input matInput [matDatepicker]="pickerMin" formControlName="dateMin">
        <mat-datepicker-toggle matSuffix [for]="pickerMin"></mat-datepicker-toggle>
        <mat-datepicker #pickerMin></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Date max (DOE)</mat-label>
        <input matInput [matDatepicker]="pickerMax" formControlName="dateMax">
        <mat-datepicker-toggle matSuffix [for]="pickerMax"></mat-datepicker-toggle>
        <mat-datepicker #pickerMax></mat-datepicker>
      </mat-form-field>
    </form>
  </div>

  <div class="block-card">
    <div class="loader" *ngIf="showLoader">
      <app-scintillating-loader></app-scintillating-loader>
    </div>
    <table mat-table matSort [dataSource]="dataSource" [hidden]="showLoader" matSortActive="currency"
      matSortDirection="desc" matSortStart="desc">
      <ng-container matColumnDef="Select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
            [checked]="engagementsSelection.hasValue() && isAllSelected()"
            [indeterminate]="engagementsSelection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? engagementsSelection.toggle(element) : null"
            [checked]="engagementsSelection.isSelected(element)" [aria-label]="checkboxLabel(element)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="expertName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Expert </th>
        <td mat-cell *matCellDef="let element">
          <a class="expert-name" [routerLink]="['/admin/experts', element.expertId]"
            [queryParams]="{tab: 'Engagements'}">
            {{element.expertName}}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element">
          <span [matTooltip]="element.email">
            {{element.email}}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="engagementTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Engagement </th>
        <td mat-cell *matCellDef="let element">
          <span class="nowrap-title">
            {{element.engagementTitle}}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Owner </th>
        <td mat-cell *matCellDef="let element">
          {{element.owner}}
        </td>
      </ng-container>
      <ng-container matColumnDef="amountOwed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount Owed </th>
        <td mat-cell *matCellDef="let element">
          {{element.amountOwed}}
        </td>
      </ng-container>
      <ng-container matColumnDef="currency">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Currency </th>
        <td mat-cell *matCellDef="let element">
          {{element.currency}}
        </td>
      </ng-container>
      <ng-container matColumnDef="paymentType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Payment Type </th>
        <td mat-cell *matCellDef="let element">
          {{element.paymentType}}
        </td>
      </ng-container>
      <ng-container matColumnDef="unitsUsed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header title="Units"> Units (adjustment) </th>
        <td mat-cell *matCellDef="let element">
          {{element.unitsUsed}} ({{element.unitsUsedAdjustment}})
        </td>
      </ng-container>
      <ng-container matColumnDef="dateOfEngagement">
        <th mat-header-cell *matHeaderCellDef mat-sort-header title="Date of engagement"> DOE </th>
        <td mat-cell *matCellDef="let element">
          {{element.dateOfEngagement ? (element.dateOfEngagement | date : 'shortDate') : ''}}
        </td>
      </ng-container>
      <ng-container matColumnDef="paymentStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let element">
          <mat-form-field class="payment-status">
            <select matNativeControl required (change)="updateEngagementPaymentStatus(element.engagementId)"
              [(ngModel)]="element.paymentStatus">
              <option *ngFor="let status of paymentStatuses" [value]="status.value">
                {{status.display}}
              </option>
            </select>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="Edit">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"> <span class="material-icons action" matTooltip="Open engagement notes"
            (click)="openNotesDialog(element)">
            sticky_note_2
          </span></td>
      </ng-container>
      <ng-container matColumnDef="Add">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"> <span class="material-icons action"
            matTooltip="Create payment correction for engagement" (click)="createPaymentForEngagement(element)">
            add
          </span> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.inactive]="!row.paymentActive">
      </tr>
    </table>
    <div [hidden]="showLoader">
      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
    </div>
    <div class="block-card-footer" *ngIf="!showLoader">
      <button class="button button--primary" (click)="openPaymentsCsvDialog()">Download csv</button>
    </div>
  </div>
</div>