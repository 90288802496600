<form [formGroup]="filterForm" class="flex flex-row items-center gap-4" data-test-id="payments-filter-form">
    <div class="flex items-center border-r pr-4">
        <mat-checkbox formControlName="verifiedOnly" class="custom-checkbox" data-test-id="payments-filter-checkbox">
            Pass verification requirements
        </mat-checkbox>
    </div>
    <mat-selection-list formControlName="paymentStatus" data-test-id="payments-filter-list">
        <mat-list-option checkboxPosition="before" *ngFor="let paymentStatus of paymentStatuses"
            [value]="paymentStatus.value" class="mat-list-option">
            {{ paymentStatus.display }}
        </mat-list-option>
    </mat-selection-list>
</form>