import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FullCalendarModule } from '@fullcalendar/angular';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { QuillModule } from 'ngx-quill';
import { DeleteEngagementDialogComponent } from './components/delete-engagement-dialog/delete-engagement-dialog.component';
import { DonutSvgComponent } from './components/donut-svg/donut-svg.component';
import { EngagementBillingFormComponent } from './components/engagement-billing-form/engagement-billing-form.component';
import { GeographicProgressComponent } from './components/geographic-progress/geographic-progress.component';
import { OpportunityTileComponent } from './components/opportunity-tile/opportunity-tile.component';
import { PaymentsFilterComponent } from './components/payments-filter/payments-filter.component';
import { ScheduleFormComponent } from './components/schedule/conference-schedule-form/schedule-form.component';
import { EmailComponent } from './components/schedule/email/email.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { SharedBtnLoaderComponent } from './components/shared-btn-loader/shared-btn-loader.component';
import { SharedCalendarComponent } from './components/shared-calendar/shared-calendar.component';
import { SharedEmailPillComponent } from './components/shared-email-pill/shared-email-pill.component';
import { SharedSectionLoaderComponent } from './components/shared-section-loader/shared-section-loader.component';
import { TextareaEditorComponent } from './components/textarea-editor/textarea-editor.component';
import { TimezoneSelectorComponent } from './components/timezone-selector/timezone-selector.component';
import { UpdateEmergencyContactComponent } from './components/update-emergency-contact/update-emergency-contact.component';
import { ViewConnectionComponent } from './components/view-connection/view-connection.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CopyClipboardDirective } from './directives/copy-clipboard.directive';
import { CountryOptionsDividersDirective } from './directives/country-options-dividers.directive';
import { EngagementBillingFormTimerDirective } from './directives/engagement-billing-form-timer.directive';
import { ExpertDiagnosticDataDirective } from './directives/expert-diagnostic-data';
import { SchedulingCallTimerDirective } from './directives/scheduling-call-timer.directive';
import { SchedulingModalTimerDirective } from './directives/scheduling-modal-timer.directive';
import { CurrencyInputComponent } from './patterns/currency-input/currency-input.component';
import { MaterialModule } from './patterns/material-module';
import { ExpertNamePipe } from './pipes/expert-name';
import { MomentTimeZonePipe } from './pipes/moment-timezone.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SalesforceLinksPipe } from './pipes/salesforce-links.pipe';
import { SourceDisplayNamePipe } from './pipes/source-display-name.pipe';
import { AppService } from './services/app.service';
import { ConferenceService } from './services/conference.service';
import { CountryService } from './services/country.service';
import { CurrencyService } from './services/currency.service';
import { EmailService } from './services/email.service';
import { StatisticsService } from './services/statistics.service';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    CopyClipboardDirective,
    CountryOptionsDividersDirective,
    CurrencyInputComponent,
    DeleteEngagementDialogComponent,
    DonutSvgComponent,
    EmailComponent,
    EngagementBillingFormComponent,
    EngagementBillingFormTimerDirective,
    ExpertDiagnosticDataDirective,
    ExpertNamePipe,
    MomentTimeZonePipe,
    OpportunityTileComponent,
    GeographicProgressComponent,
    SafePipe,
    SalesforceLinksPipe,
    ScheduleComponent,
    ScheduleFormComponent,
    SchedulingCallTimerDirective,
    SchedulingModalTimerDirective,
    SharedBtnLoaderComponent,
    SharedCalendarComponent,
    SharedEmailPillComponent,
    SharedSectionLoaderComponent,
    SourceDisplayNamePipe,
    TextareaEditorComponent,
    TimezoneSelectorComponent,
    UpdateEmergencyContactComponent,
    ViewConnectionComponent,
    PaymentsFilterComponent,
  ],
  imports: [
    CdkTableModule,
    CommonModule,
    EditorModule,
    FormsModule,
    FullCalendarModule,
    MaterialModule,
    NgxCsvParserModule,
    RouterModule,
    ScrollingModule,
    QuillModule,
  ],
  exports: [
    CdkTableModule,
    ClickOutsideDirective,
    CommonModule,
    CopyClipboardDirective,
    CountryOptionsDividersDirective,
    CurrencyInputComponent,
    DeleteEngagementDialogComponent,
    DonutSvgComponent,
    EditorModule,
    EngagementBillingFormComponent,
    EngagementBillingFormTimerDirective,
    ExpertDiagnosticDataDirective,
    ExpertNamePipe,
    FormsModule,
    MaterialModule,
    OpportunityTileComponent,
    SafePipe,
    SalesforceLinksPipe,
    ScheduleComponent,
    ScheduleFormComponent,
    ScrollingModule,
    SharedBtnLoaderComponent,
    SharedCalendarComponent,
    SharedEmailPillComponent,
    SharedSectionLoaderComponent,
    SourceDisplayNamePipe,
    TextareaEditorComponent,
    TimezoneSelectorComponent,
    UpdateEmergencyContactComponent,
    ViewConnectionComponent,
    PaymentsFilterComponent,
  ],
  providers: [
    AppService,
    ConferenceService,
    CountryService,
    CurrencyService,
    EmailService,
    StatisticsService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class BtxSharedModule {}
