import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import {
  EngagementPaymentTypes,
  EngagementsService,
  IEngagement,
  PaymentStatus,
} from '@techspert-io/engagements';
import { ToastService } from '@techspert-io/user-alerts';
import { ExportToCsv } from 'export-to-csv';
import * as moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  forkJoin,
} from 'rxjs';
import {
  filter,
  map,
  scan,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { EngagementsDialogComponent } from '../../admin-portal/payments/engagements-dialog/engagements-dialog.component';
import { NotesDialogComponent } from './notes-dialog/notes-dialog.component';
import { IPaymentDashboardEngagement } from './payments-models';

interface ISelect {
  value: PaymentStatus;
  display: string;
}

interface ITranferWiseUploadObject {
  name: string;
  recipientEmail: string;
  paymentReference: string;
  receiverType: string;
  amountCurrency: string;
  amount: number;
  sourceCurrency: string;
  targetCurrency: string;
  type: string;
}

type FilterForm = FormGroup<{
  paymentStatus: FormControl<string[]>;
  dateMin: FormControl<string>;
  dateMax: FormControl<string>;
  verifiedOnly: FormControl<boolean>;
}>;

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit, OnDestroy {
  @ViewChild('PaymentsCsvDialog') paymentsCsvDialog: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  private destroy$ = new Subject();
  private sidebarDimensions = {
    width: '375px',
    height: '100vh',
    position: { right: '0' },
  };

  paymentTypes: EngagementPaymentTypes[] = [];
  paymentStatuses: ISelect[] = [
    { value: 'not-paid', display: 'Not paid' },
    { value: 'payment-sent', display: 'Payment sent' },
    { value: 'payment-received', display: 'Payment received' },
    { value: 'not-claimed', display: 'Not claimed' },
  ];
  displayedColumns = [
    'Select',
    'expertName',
    'email',
    'engagementTitle',
    'owner',
    'amountOwed',
    'currency',
    'paymentType',
    'unitsUsed',
    'dateOfEngagement',
    'paymentStatus',
    'Edit',
    'Add',
  ];
  dataSource: MatTableDataSource<IPaymentDashboardEngagement>;
  updatePaymentStatus = false;
  allEngagements: IPaymentDashboardEngagement[] = [];
  engagementsSelection = new SelectionModel<IPaymentDashboardEngagement>(
    true,
    []
  );
  showLoader: boolean;

  getEngagements$ = new Observable<IPaymentDashboardEngagement[]>();

  filterForm: FilterForm = new FormGroup({
    paymentStatus: new FormControl(['not-paid']),
    dateMin: new FormControl(
      moment().subtract(1, 'month').format('YYYY-MM-DD')
    ),
    dateMax: new FormControl(moment().format('YYYY-MM-DD')),
    verifiedOnly: new FormControl(true),
  });

  searchForm = new FormGroup({
    search: new FormControl(''),
    paymentTypes: new FormControl<EngagementPaymentTypes[]>([]),
  });

  newEngagements$ = new BehaviorSubject<IPaymentDashboardEngagement[]>([]);

  constructor(
    private dialog: MatDialog,
    private engagementsService: EngagementsService,
    private toastService: ToastService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const queryParams$ = this.route.queryParamMap.pipe(
      map((p) => p.get('opportunityId')),
      tap((opportunityId) => {
        opportunityId &&
          this.filterForm.patchValue({ dateMin: null, dateMax: null });
      })
    );

    this.showLoader = true;

    const formChanges$ = combineLatest([
      this.filterForm.valueChanges.pipe(startWith(this.filterForm.value)),
      queryParams$,
    ]).pipe(
      tap(() => (this.showLoader = true)),
      switchMap(
        ([
          { paymentStatus, dateMin, dateMax, verifiedOnly },
          opportunityId,
        ]) => {
          return this.engagementsService.queryExtended({
            paymentStatus: paymentStatus.join(','),
            dateMin: moment(dateMin).format('YYYY-MM-DD'),
            dateMax: moment(dateMax).format('YYYY-MM-DD'),
            opportunityId,
            verifiedOnly,
          });
        }
      )
    );

    const searchFormChanges$ = this.searchForm.valueChanges.pipe(
      startWith(this.searchForm.value)
    );

    combineLatest([
      this.newEngagements$.pipe(
        scan<IPaymentDashboardEngagement[], IPaymentDashboardEngagement[]>(
          (acc, value) => [...acc, ...value],
          []
        )
      ),
      formChanges$,
      searchFormChanges$,
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([newEngagements, engagements]) => {
        const engagementDict = [...engagements, ...newEngagements].reduce<
          Record<string, IPaymentDashboardEngagement>
        >((acc, cur) => ({ ...acc, [cur.engagementId]: cur }), {});

        this.allEngagements = Object.values(engagementDict);

        this.setPaymentTypeOptions(this.allEngagements);

        this.allEngagements = this.filterEngagements(this.allEngagements, {
          searchTerm: this.searchForm.value.search || '',
          paymentTypes: this.searchForm.value.paymentTypes || [],
        });
        this.formulateEngagements(this.allEngagements);
        this.engagementsSelection.clear();
        this.showLoader = false;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  isAllSelected(): boolean {
    if (this.dataSource && this.dataSource.data) {
      const numSelected = this.engagementsSelection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
    return true;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.engagementsSelection.clear()
      : this.dataSource.data.forEach((engagement) =>
          this.engagementsSelection.select(engagement)
        );
  }

  checkboxLabel(row?: IPaymentDashboardEngagement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all engagements`;
    }
    return `${
      this.engagementsSelection.isSelected(row) ? 'deselect' : 'select'
    } engagement: ${row.engagementTitle}`;
  }

  downloadEngagementsCsvHandler(): void {
    const selectedEngagements = this.engagementsSelection.selected;
    const transferWiseObjects =
      this.aggregateEngagementsToTransferWiseObjects(selectedEngagements);
    this.downloadEngagementsCsv(transferWiseObjects);
    if (this.updatePaymentStatus && selectedEngagements.length) {
      const updates$ = selectedEngagements
        .filter((engagement) => engagement.paymentStatus === 'not-paid')
        .map((e) =>
          this.engagementsService.updateLegacy({
            engagementId: e.engagementId,
            paymentStatus: 'payment-sent',
          })
        );

      forkJoin(updates$)
        .pipe(
          tap(() => {
            selectedEngagements.forEach((selectedEngagement) => {
              const index = this.allEngagements.findIndex(
                (engagement) =>
                  engagement.engagementId === selectedEngagement.engagementId
              );
              this.allEngagements[index].paymentStatus = 'payment-sent';
            });
            this.closePaymentsCsvDialog();
            this.engagementsSelection.clear();
          })
        )
        .subscribe();
    }
  }

  openPaymentsCsvDialog(): void {
    if (this.engagementsSelection.selected.length) {
      this.paymentsCsvDialog.nativeElement.style.display = 'flex';
      this.paymentsCsvDialog.nativeElement.showModal();
    } else {
      this.toastService.sendMessage(
        'Please select at least one payment',
        'error'
      );
    }
  }

  closePaymentsCsvDialog(): void {
    this.paymentsCsvDialog.nativeElement.style.display = 'none';
    this.paymentsCsvDialog.nativeElement.close();
    this.updatePaymentStatus = false;
  }

  openNotesDialog(engagement: IPaymentDashboardEngagement): void {
    this.dialog
      .open(NotesDialogComponent, {
        ...this.sidebarDimensions,
        data: {
          engagement,
        },
      })
      .afterClosed()
      .subscribe();
  }

  createPaymentForEngagement(engagement: IPaymentDashboardEngagement): void {
    const payment: IEngagement = {
      engagementTitle: `Payment - [${engagement.expertName}]`,
      engagementType: 'payment',
      dateOfEngagement: new Date().toISOString(),
      paymentStatus: 'not-paid',
      rate: 0,
      quantityEngaged: 0,
      unitsUsed: 0,
      unitsUsedAdjustment: 0,
      amountOwed: 0,
      paymentType: engagement.paymentType,
      notes: `Payment correction for ${engagement.engagementTitle}`,
      transactionId: '',
      currency: engagement.currency,
      email: engagement.email,
      opportunityName: engagement.opportunityName,
      opportunityId: engagement.opportunityId,
      expertName: engagement.expertName,
      expertId: engagement.expertId,
      paymentActive: true,
      lastAccepted: engagement.lastAccepted,
      paymentProvider: 'Techspert',
    };
    this.dialog
      .open<
        EngagementsDialogComponent,
        { engagement: IEngagement },
        { action: string; engagement: IEngagement }
      >(EngagementsDialogComponent, {
        ...this.sidebarDimensions,
        data: {
          engagement: payment,
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => res && res.action === 'create' && !!res.engagement),
        switchMap((res) =>
          this.engagementsService.create(res.engagement).pipe(
            tap((createdEngagement) => {
              this.newEngagements$.next([
                {
                  ...createdEngagement,
                  owner: engagement.owner,
                },
              ]);
            })
          )
        )
      )
      .subscribe();
  }

  updateEngagementPaymentStatus(engagementId: string): void {
    this.engagementsService
      .updateLegacy({
        engagementId,
        paymentStatus: this.allEngagements.find(
          (engagement) => engagementId === engagement.engagementId
        ).paymentStatus,
        paymentProvider: 'Techspert',
      })
      .subscribe();
  }

  private aggregateEngagementsToTransferWiseObjects(
    selectedEngagements: IPaymentDashboardEngagement[]
  ): ITranferWiseUploadObject[] {
    const aggregatedDictionary = selectedEngagements.reduce(
      (acc, engagement) => {
        if (acc[engagement.expertId]) {
          acc[engagement.expertId].amount += engagement.amountOwed;
        } else {
          acc[engagement.expertId] = {
            name: `${engagement.expertName}`,
            recipientEmail: engagement.email,
            paymentReference: engagement.expertId
              ? engagement.expertId.substring(0, 10)
              : 'No Expert Id',
            receiverType: 'PRIVATE',
            amountCurrency: engagement.currency,
            amount: engagement.amountOwed,
            sourceCurrency: 'USD',
            targetCurrency: engagement.currency,
            type: 'EMAIL',
          };
        }
        return acc;
      },
      {}
    );
    return Object.values(aggregatedDictionary);
  }

  private setPaymentTypeOptions(engagements: IPaymentDashboardEngagement[]) {
    this.paymentTypes = [
      ...new Set(engagements.map(({ paymentType }) => paymentType)),
    ].sort();

    if (!this.searchForm.controls.paymentTypes.dirty) {
      this.searchForm.controls.paymentTypes.setValue(
        this.paymentTypes.filter((d) => d !== 'thirdParty'),
        { emitEvent: false }
      );
    }
  }

  private downloadEngagementsCsv(
    transferWisePaymentObjects: ITranferWiseUploadObject[]
  ): void {
    const csvExporter = new ExportToCsv({
      fieldSeparator: ',',
      filename: `payments-batch-${new Date().toISOString().slice(0, 10)}`,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: `payments-batch-${new Date().toISOString().slice(0, 10)}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    });

    csvExporter.generateCsv(transferWisePaymentObjects);
  }

  private filterEngagements(
    allEngagements: IPaymentDashboardEngagement[],
    search: { searchTerm: string; paymentTypes: EngagementPaymentTypes[] }
  ): IPaymentDashboardEngagement[] {
    return allEngagements.filter(
      (engagement) =>
        (engagement.expertName
          .toLowerCase()
          .includes(search.searchTerm.toLowerCase()) ||
          (search.searchTerm.length > 8 &&
            engagement.expertId &&
            engagement.expertId
              .toLowerCase()
              .includes(search.searchTerm.toLowerCase()))) &&
        search.paymentTypes.includes(engagement.paymentType)
    );
  }

  private formulateEngagements(
    engagements: IPaymentDashboardEngagement[]
  ): void {
    this.dataSource = new MatTableDataSource<IPaymentDashboardEngagement>(
      engagements
    );
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
